<template>
	<div>
		<base-material-card color="primary" class="px-5 py-3">
			<template v-slot:heading>
				<div class="display-1 font-weight-light">Agents Stats</div>
				<div class="subtitle-2 font-weight-light">
					All Agents Stats And Details
				</div>
			</template>
			<v-card-title>
				<v-btn icon color="primary" @click="getAgentStats()">
					<v-icon>mdi-refresh</v-icon>
				</v-btn>
				&nbsp;
				<v-btn outlined color="primary" @click="showRegistrationDialog()">
					Create
				</v-btn>
				<v-spacer></v-spacer>
				<v-text-field
					append-icon="mdi-magnify"
					v-model="search"
					label="Search"
					hide-details
					single-line
					clearable
				></v-text-field>
			</v-card-title>
			<v-card-text>
				<v-data-table :headers="headers" :items="filteredStats">
					<template v-slot:item.assigned_product_type="{ item }">
						<div style="max-width: 150px">
							{{ newUserAssignedProductValue[item.user.id] }}
							<!-- <v-autocomplete
								v-model="newUserAssignedProductValue[item.user.id]"
								@change="updateAgentAssignedProductType(item.user)"
								:items="$store.state.application.application_types"
								dense
								solo
								flat
								outlined
								hide-details
								block
								class="elevation-0"
							></v-autocomplete> -->
						</div>
					</template>
					<template v-slot:item.actions="{ item }">
						<template v-if="item.user.role != 'admin'">
							<v-btn
								icon
								color="success"
								v-if="item.user.is_active"
								@click="inactiveUserAccount(item.user)"
								title="Click to Inactive agent account."
							>
								<v-icon>mdi-account-check</v-icon>
							</v-btn>
							<v-btn
								icon
								color="orange"
								v-else
								@click="activeUserAccount(item.user)"
								title="Click to Active agent account."
							>
								<v-icon>mdi-account-alert</v-icon>
							</v-btn>
							<v-btn
								icon
								color="error"
								@click="deleteUserAccount(item.user)"
								title="Click to Delete agent account."
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
							<!-- <v-btn icon color="primary">
								<v-icon>mdi-magnify</v-icon>
							</v-btn>-->
							<v-btn
								@click="loginWithUserId(item)"
								v-if="currentUserRole == 'admin'"
								title="Click to login To the user account"
								color="purple"
								icon
							>
								<v-icon>mdi-login</v-icon>
							</v-btn>
						</template>
						<v-btn
							@click="showRegistrationDialog(item)"
							v-if="currentUserRole == 'admin'"
							title="Click To edit user account"
							color="secondary"
							icon
						>
							<v-icon>mdi-update</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-card-text>
		</base-material-card>
		<v-dialog
			@click:outside="closeRegistrationDialog()"
			v-model="registrationDialog"
			v-if="registrationDialog"
			max-width="700px"
			persistent
		>
			<v-card>
				<register
					@close-dialog="closeRegistrationDialog()"
					@registered="getAgentStats()"
					:selectedModel="selectedModel"
					:is_agent="true"
					:is_page="false"
					v-if="registrationDialog"
				/>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import register from "@/components/forms/register";
	export default {
		components: { register },
		data() {
			return {
				selectedModel: null,
				newUserAssignedProductValue: [],
				registrationDialog: false,
				search: null,
				stats: [],
				headers: [
					{
						sortable: true,
						text: "Agent ID",
						value: "agent_id",
						align: "left",
					},
					{
						sortable: true,
						text: "Name",
						value: "user.name",
						align: "left",
					},
					{
						sortable: true,
						text: "Total Aps.",
						value: "applications_count",
						align: "left",
					},
					{
						sortable: true,
						text: "24 Hrs. Aps.",
						value: "past_24_hours_applications_count",
						align: "left",
					},
					{
						sortable: true,
						text: "24 Hrs. Launched Aps.",
						value: "past_24_hours_launched_applications_count",
						align: "left",
					},
					{
						sortable: true,
						text: "Assigned Product",
						value: "assigned_product_type",
						align: "left",
					},
					{
						sortable: true,
						text: "Role",
						value: "user.role",
						align: "left",
					},
					{
						sortable: false,
						text: "Actions",
						value: "actions",
						align: "right",
					},
				],
			};
		},
		created() {
			this.getAgentStats();
		},
		watch: {
			stats(val) {
				let $this = this;
				if (val && val.length)
					val.forEach((agent) => {
						$this.newUserAssignedProductValue[agent.user.id] =
							agent.assigned_product_type;
					});
				else $this.newUserAssignedProductValue = [];
			},
		},
		computed: {
			filteredStats() {
				let $this = this;
				return $this.stats && $this.stats.length
					? $this.stats.filter(function (item) {
							return $this.isSearchStringInData($this.search, item);
					  })
					: [];
			},
		},
		methods: {
			async loginWithUserId(agent) {
				if (!confirm("Are you sure you want to login to the agent account?"))
					return false;
				await this.$store.dispatch("auth/loginWithUserId", {
					user_id: agent?.user?.id,
				});
			},
			async inactiveUserAccount(user) {
				if (!confirm("User account will be deactivated?")) return false;
				this.registrationDialog = false;
				return await this.post(
					this.baseUrl + `/inactive-user-account`,
					{ user_id: user.id },
					false
				).then((data) => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.getAgentStats();
					}
				});
			},
			async activeUserAccount(user) {
				if (!confirm("User account will be activated?")) return false;
				this.registrationDialog = false;
				return await this.post(
					this.baseUrl + `/active-user-account`,
					{ user_id: user.id },
					false
				).then((data) => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.getAgentStats();
					}
				});
			},
			async deleteUserAccount(user) {
				if (!confirm("User account will be deleted?")) return false;
				if (
					!confirm(
						"All applications related to this account will be also deleted?"
					)
				)
					return false;
				if (!confirm("This action is irreversible.")) return false;
				this.registrationDialog = false;
				return await this.post(
					this.baseUrl + `/delete-user-account`,
					{ user_id: user.id },
					false
				).then((data) => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.getAgentStats();
					}
				});
			},
			async updateAgentAssignedProductType(agent) {
				this.registrationDialog = false;
				return await this.post(
					this.baseUrl + `/update-agent-assigned-product-type`,
					{
						agent: agent.id,
						product_type: this.newUserAssignedProductValue[agent.id],
					},
					false
				).then((data) => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.getAgentStats();
					}
				});
			},
			async getAgentStats() {
				this.registrationDialog = false;
				this.stats = [];
				return await this.post(
					this.baseUrl + `/get-agents-stats`,
					{},
					false
				).then((data) => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.stats = data.data;
					}
				});
			},
			showRegistrationDialog(user = null) {
				this.selectedModel = null;
				if (user) this.selectedModel = user;
				this.registrationDialog = true;
			},
			closeRegistrationDialog() {
				this.selectedModel = null;
				this.registrationDialog = false;
			},
		},
	};
</script>
