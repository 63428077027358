<template>
  <v-card flat tile elevation-0 class="transparent ma-0 pa-0">
    <v-card-text>
      <v-data-table
        :sort-by="['application_id']"
        :sort-desc="true"
        :headers="applicationsTableHeaders"
        :items="agent_applications_list"
        class="elevation-1 transparent applications-table"
        :hide-default-footer="true"
        :options="{
          page: agent_applications_list_pagination.current_page,
          itemsPerPage: agent_applications_list_pagination.per_page,
        }"
      >
        <template v-slot:item.application_id="{ item }">
          <router-link
            class="black--text d-flex"
            :to="showApplicationDetails(item)"
          >
            <v-badge
              v-if="isApplicationHasActiveAlerts(item)"
              :color="getApplicationAlertDotColor(item)"
              dot
              left
            >
              {{ item.application_id }}
            </v-badge>
            <div v-else>
              {{ item.application_id }}
            </div>
          </router-link>
        </template>
        <template v-slot:item.client.payload.first_name="{ item }">
          <router-link
            class="black--text d-flex"
            :to="showApplicationDetails(item)"
          >
            {{
              item.client.payload &&
              item.client.payload.first_name &&
              item.client.payload.last_name
                ? `${item.client.payload.first_name} ${item.client.payload.last_name}`
                : "N/A"
            }}
          </router-link>
        </template>
        <template v-slot:item.agent.user.name="{ item }">
          <router-link
            class="black--text d-flex"
            :to="showApplicationDetails(item)"
          >
            {{ item.agent.user.name }}
          </router-link>
        </template>
        <template v-slot:item.address="{ item }">
          <router-link
            class="black--text d-flex"
            :to="showApplicationDetails(item)"
          >
            {{ item.address.replace(", USA", "") }}
          </router-link>
        </template>
        <template v-slot:item.created_at_formatted="{ item }">
          <router-link
            class="black--text d-flex"
            :to="showApplicationDetails(item)"
          >
            <!-- {{ item.created_at_formatted | moment("from", "now") }} -->
            <!-- {{ item.created_at_formatted | moment("calendar") }} -->
            {{ item.created_at_formatted }}
          </router-link>
        </template>
        <template v-slot:item.status="{ item }">
          <router-link
            class="black--text d-flex text-capitalize"
            :to="showApplicationDetails(item)"
          >
            {{ item.status.toString().replace(/_/g, " ") }}
          </router-link>
        </template>
        <template v-slot:item.application_type="{ item }">
          <router-link
            class="black--text d-flex text-capitalize"
            :to="showApplicationDetails(item)"
          >
            {{
              item.application_type
                ? item.application_type.toString().replace(/_/g, " ")
                : "flood"
            }}
          </router-link>
        </template>
        <template v-slot:item.min_quote_amount="{ item }">
          <router-link
            class="black--text d-flex"
            :to="showApplicationDetails(item)"
          >
            {{
              item.min_quote_amount &&
              !["0", 0, null].includes(item.min_quote_amount)
                ? `$${item.min_quote_amount}`
                : "N/A"
            }}
          </router-link>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex" @click.stop>
            <template v-if="item.status == 'complete'">
              <v-btn
                @click="
                  sendDownloadApplicationQuoteDialogShow(item, null, 'download')
                "
                color="success"
                icon
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn
                @click="
                  sendDownloadApplicationQuoteDialogShow(
                    item,
                    null,
                    'send-email'
                  )
                "
                color="primary"
                icon
              >
                <v-icon>mdi-email-arrow-right-outline</v-icon>
              </v-btn>
              <v-btn
                @click="
                  sendDownloadApplicationQuoteDialogShow(item, null, 'send-sms')
                "
                color="primary"
                icon
              >
                <v-icon>mdi-cellphone-message</v-icon>
              </v-btn>
            </template>
          </div>
        </template>

        <template v-slot:footer="">
          <v-divider></v-divider>
          <v-row class="mt-4" no-gutters>
            <v-btn
              @click="reloadApplications()"
              class="pa-1 ma-2"
              color="primary"
              icon
              small
              dense
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <div class="pa-1 ma-2 v-btn--outlined rounded primary--text">
              <b>
                {{
                  agent_applications_list_pagination.per_page *
                    (agent_applications_list_pagination.current_page - 1) +
                  1
                }}
              </b>
              -
              <b>
                {{
                  agent_applications_list_pagination.per_page *
                    (agent_applications_list_pagination.current_page - 1) +
                  agent_applications_list.length
                }}
              </b>
              of
              <b>
                {{ agent_applications_list_pagination.total }}
              </b>
            </div>
            <!-- <div class="pa-1 ma-2 v-btn--outlined rounded grey--text">
                        Total :
                        <b>
                            {{ agent_applications_list_pagination.total }}
                        </b>
                    </div>
                    <div class="pa-1 ma-2 v-btn--outlined rounded grey--text">
                        Current :
                        <b>
                            {{ agent_applications_list.length }}
                        </b>
                    </div> -->
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  class="pa-1 ma-2"
                  dense
                  small
                  outlined
                >
                  Per Page:
                  <b>
                    {{
                      itemsPerPageOptions.find(
                        (opt) =>
                          opt.value ==
                          agent_applications_list_pagination.per_page
                      ).text
                    }}
                  </b>
                  <v-icon> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="
                    ((agent_applications_list_pagination.per_page = opt.value),
                    (agent_applications_list_pagination.current_page = 1)),
                      reloadApplications()
                  "
                  v-for="(opt, index) in itemsPerPageOptions"
                  :key="index"
                >
                  <v-list-item-title>{{ opt.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-pagination
              style="justify-content: end"
              @input="reloadApplications()"
              v-model="agent_applications_list_pagination.current_page"
              :length="agent_applications_list_pagination.last_page"
              :total-visible="5"
            ></v-pagination>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
    <sendDownloadApplicationQuoteDialog
      v-if="sendDownloadApplicationQuoteDialog.dialogModel"
      v-bind="sendDownloadApplicationQuoteDialog"
      @closeDialog="sendDownloadApplicationQuoteDialogClose()"
    />
  </v-card>
</template>

<script>
import sendDownloadApplicationQuoteDialog from "@/components/dialogs/sendDownloadApplicationQuoteDialog";
import { mapActions } from "vuex";

export default {
  components: {
    sendDownloadApplicationQuoteDialog,
  },
  props: {
    agent_applications_list: {
      required: true,
      default: () => {
        return [];
      },
    },
    agent_applications_list_pagination: { required: true, default: {} },
  },
  data() {
    return {
      sendDownloadApplicationQuoteDialog: {
        dialogModel: false,
        selectedModel: null,
        formTypeProp: "send",
        selectedApplication: null,
        selectedQuote: null,
      },
      itemsPerPageOptions: [
        {
          text: 25,
          value: 25,
        },
        {
          text: 50,
          value: 50,
        },
        {
          text: 100,
          value: 100,
        },
        {
          text: 300,
          value: 300,
        },
        {
          text: "All",
          value: 99999,
        },
      ],
    };
  },
  computed: {
    applicationsTableHeaders() {
      return [
        {
          text: "Application Id",
          align: "start",
          sortable: true,
          value: "application_id",
        },
        { text: "Insured", value: "client.payload.first_name" },
        { text: "Agent", value: "agent.user.name" },
        { text: "Address", value: "address" },
        { text: "Created At", value: "created_at_formatted" },
        { text: "Status", value: "status" },
        { text: "Type", value: "application_type" },
        { text: "Quote", value: "min_quote_amount" },
        { text: "Source", value: "application_source" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  watch: {},
  created() {},
  methods: {
    reloadApplications() {
      this.$emit("reloadApplications");
    },
    getApplicationFirstActiveAlert(application) {
      return application?.notes?.find(
        (note) => note?.payload?.is_alert && note?.payload?.is_active
      );
    },
    getApplicationAlertDotColor(application) {
      return this.$helpers.getApplicationAlertDotColor(application);
    },
    isApplicationHasActiveAlerts(application) {
      return !!this.getApplicationFirstActiveAlert(application);
    },
    showApplicationDetails(application) {
      return {
        name: "application-details",
        params: { application_id: application.application_id },
      };
    },
    async sendDownloadApplicationQuoteDialogClose() {
      this.sendDownloadApplicationQuoteDialog.dialogModel = false;
      this.sendDownloadApplicationQuoteDialog.formTypeProp = null;
      this.sendDownloadApplicationQuoteDialog.selectedQuote = null;
      this.sendDownloadApplicationQuoteDialog.selectedApplication = null;
    },
    async sendDownloadApplicationQuoteDialogShow(
      application,
      quote = null,
      formTypeProp = "send-email"
    ) {
      this.sendDownloadApplicationQuoteDialog.dialogModel = true;
      this.sendDownloadApplicationQuoteDialog.formTypeProp = formTypeProp;
      this.sendDownloadApplicationQuoteDialog.selectedQuote = quote;
      this.sendDownloadApplicationQuoteDialog.selectedApplication = application;
    },
  },
};
</script>

<style lang="scss">
#agent-portal .applications-table a,
.applications-table .black--text,
.applications-table .black--text * {
  color: black !important;
}
</style>
